import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import Button from '@/hoc/components/Button'
import style from './style.module.scss'
import Layout from '@/components/Common/Layout/Layout';
import { useRouter } from 'next/router';

const Error404 = (props) => {
  const { menuHeader, homeFooter, isMobile } = props
  const router = useRouter()
  const { t, lang } = useTranslation('translations-auction')
  const homeFooterData = isMobile ? null : homeFooter || {}
 
  return (
    <Layout
    headerCities={menuHeader || {}}
    footerLinks={homeFooterData}
    locale={lang}
    wrapperClass={style.footer404}
  >
    <div className={style.wrapper}>
    <div className={style.content}>
        <img src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/404.png`} alt={t('ErrorPage.NOT_FOUND')} className={style.image}/>
        <h1 className={style.heading}>{t('ErrorPage.CANT_FIND_PAGE')}</h1>
         <div className={style.btn}>
          <Button
            classes='homeSearchV2'
            text={t('ErrorPage.BTN_TEXT')}
            textClass={'backButton'}
            onClick={() => {
              router.push('/')
            }}
          />
          </div>
          </div>
    </div>
    </Layout>
  )
}

export default Error404