import Error404 from '@/components/Common/Error404'
import React, { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation';
import { useMediaQuery } from 'react-responsive';
import { getHeaderFooterLinks } from '@/services/headerService';

export default function _404() {
  const [homeFooter, setHomeFooter] = useState(null)
  const [menuHeader, setMenuHeader] = useState(null)
  const isMobile = useMediaQuery({ maxWidth: 991 })
  const { lang } = useTranslation('translations-auction')

  useEffect(() => {
    async function fetchAPI () {
      const response = await getHeaderFooterLinks(lang)
      const {navigationLinks, footerLinks} = response ?? {};
      setHomeFooter(footerLinks)
      setMenuHeader(navigationLinks)
    }
    fetchAPI()
  }, [])
  
  return <>
          {homeFooter && menuHeader && <Error404 menuHeader={menuHeader} homeFooter={homeFooter} isMobile={isMobile}/>}
        </>
}